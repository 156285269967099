import styled from '@emotion/styled'
import { ItemMobile } from 'app/components/HotelsList/ItemMobile'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const HotelsList = memo(function HotelsList({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {uniqBy(items, 'title').map((item, index) => (
          <>
            <Media greaterThanOrEqual="desktopSmall">
              <Item
                key={index}
                variant={index % 2 ? 'dark' : 'default'}
                {...item}
              />
            </Media>
            <Media lessThan="desktopSmall">
              <ItemMobile
                key={index}
                variant={index % 2 ? 'dark' : 'default'}
                {...item}
              />
            </Media>
          </>
        ))}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section``
