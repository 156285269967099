import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
  onClick?: (e: any) => void
}

export const Dot = memo(function Dot({ className, onClick }: Props) {
  return <Container className={className} onClick={onClick} />
})

const Container = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 1.25rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  border-radius: 50%;
  opacity: 0.3;
  cursor: pointer;

  &.active {
    opacity: 1;
  }
`
