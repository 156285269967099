import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  description?: string
  pageTitle?: string
  title?: string
}

export const IntroAlt = memo(function IntroAlt({
  description,
  pageTitle,
  title,
}: Props) {
  const [animObjectRef, isAnimObjectVisible] = useInView({ triggerOnce: true })

  return (
    <Container>
      <Wrapper
        row
        space="between"
        dial={8}
        ref={animObjectRef}
        className={isAnimObjectVisible ? 'visible' : undefined}
      >
        {pageTitle || title ? (
          <Inner row={false} align="start">
            {pageTitle ? <PageTitle>{pageTitle}</PageTitle> : null}

            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
          </Inner>
        ) : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 18.75rem 10.833vw 6.25rem;

  @media (max-width: 1199px) {
    margin: 11.6875rem 1.5rem 6.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 71.125rem;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(2.75rem);
  transition: 0.4s 0.2s ease-in-out;
  &.visible {
    opacity: 1;
    transform: none;
  }

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
`

const Inner = styled(FlexBox)`
  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
`

const PageTitle = styled.div`
  margin-bottom: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.3em;
  line-height: 1.125rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
    text-align: center;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 2.25rem;
  letter-spacing: 0.2em;
  line-height: 2.5625rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 2.0625rem;
    text-align: center;
  }

  strong {
    font-weight: 600;
  }
`

const Description = styled(FlexBox)`
  width: 49.5%;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;

  @media (max-width: 1199px) {
    width: 100%;
    max-width: 30rem;
    margin-top: 3rem;
    text-align: center;
  }
`
