import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
}

export const Paragraph = memo(function Paragraph({ description }: Props) {
  return (
    <Container row={false} dial={8} tag="section">
      <Wrapper>
        {description ? (
          <Box>
            <FadeIn>
              <Outline row={false} dial={5}>
                <FadeInUp>
                  <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </FadeInUp>
                <FadeIn>
                  <Icon src="/logo-paragraph.svg" />
                </FadeIn>
              </Outline>
            </FadeIn>
          </Box>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 38.75rem;
  margin-bottom: 5.3125rem;
  background: url('pattern-background-outline-light.svg') no-repeat top center;
  background-size: cover;

  @media (max-width: 1199px) {
    height: 25rem;
    margin-bottom: 9.375rem;
  }
`

const Wrapper = styled.div`
  margin: 0 10.833vw -5.3125rem;

  @media (max-width: 1439px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 1199px) {
    margin: 0 1.5rem -9.375rem;
  }
`

const Box = styled(FlexBox)`
  max-width: 46.875rem;
  padding: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 1199px) {
    max-width: 30rem;
    padding: 1.5rem;
    padding-bottom: 2.5rem;
  }
`

const Outline = styled(FlexBox)`
  position: relative;
  padding: 4.875rem 9rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight2};
  background: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 1199px) {
    padding: 3.75rem 2.5rem 5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 200;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 2.1875rem;
  }
`

const Icon = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  max-height: 2.5rem;
  padding: 0 2.5rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
`
